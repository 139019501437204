body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: -webkit-fill-available;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

* {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul.mission {
  list-style: none;
}

ul.mission li::before {
  content: "●";
  color: #f30813;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-bottom: 2em;
}

.parento {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.childo {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.bg-one {
  background-image: url(./assets/images/news_banner.png);
}

.bg-two {
  background-image: url(./assets/images/hero_mobile.png);
}

.parento:hover .childo,
.parento:focus .childo {
  -ms-transform: scale(2.1);
  -moz-transform: scale(2.1);
  -webkit-transform: scale(2.1);
  -o-transform: scale(2.1);
  transform: scale(2.1);
}

.parento:hover .childo:before,
.parento:focus .childo:before {
  display: block;
}

.parento:hover a,
.parento:focus a {
  display: block;
}

.childo:before {
  content: "";
  display: none;
  height: 100%;
  width: 100%;
}

/* Media Queries */
@media screen and (max-width: 960px) {
  .parento {
    width: 100%;
    margin: 20px 0px;
  }
  .wrapper {
    padding: 20px 20px;
  }
}

@media only screen and (max-width: 600px) {
  .childo:before {
    content: "";
    display: none;
    height: 100%;
    width: 100%;
    background-image: none;
  }

  .parento:hover .childo,
  .parento:focus .childo {
    -ms-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    -o-transform: none !important;
    transform: none !important;
  }
}

.block-ellipsis {
  display: -webkit-box;
  display: block;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}


.highter{
  height: -webkit-fill-available;
  height: 100vh;
}

.login-bg{
  background-image: url(./assets/images/login_bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}